<template>
  <div>
    <table-page dataName="list"
                ref="table"
                @on-success="fetchSuccess"
                :params="{activity_id:$route.query.id}"
                :search="search"
                :request="questionLuckyLogs">
      <template slot="button">
        <FileExport :params="exportParams"
                    class="mr-15"
                    v-if="exportParams">导出数据</FileExport>
        <el-button icon="el-icon-download"
                   class="mr-15"
                   @click="fetchStatisticals()">统计</el-button>
        <el-button icon="el-icon-download"
                   class="mr-15"
                   @click="batch()">一键补发</el-button>
      </template>
      <el-table-column prop="user_name"
                       show-overflow-tooltip
                       label="姓名">
      </el-table-column>
      <el-table-column prop="user_tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="user_id_code"
                       show-overflow-tooltip
                       label="身份证号">
      </el-table-column>
      <el-table-column prop="user_openid"
                       show-overflow-tooltip
                       label="OPENID">
      </el-table-column>
      <el-table-column prop="prize_name"
                       show-overflow-tooltip
                       label="奖品名称">
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="中奖时间">
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="预约网点">
        <template slot-scope="scope">
          <span>{{`${scope.row.postal_province || ''}${scope.row.postal_city || ''}${scope.row.postal_district || ''}${scope.row.postal_net || ''}`}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="marketer_name"
                       show-overflow-tooltip
                       label="关联员工">
      </el-table-column>
      <el-table-column prop="areas_name"
                       show-overflow-tooltip
                       label="员工所属网点">
        <template slot-scope="scope">
          <span>{{`${scope.row.marketer_province || ''}${scope.row.marketer_city || ''}${scope.row.marketer_district || ''}${scope.row.marketer_net || ''}`}}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="备注">
        <template slot-scope="scope">
          <span>{{scope.row.err_msg==='SUCCESS' ? '':scope.row.err_msg}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       width="200px">
        <template slot-scope="scope">
          <el-button type="text"
                     class="mr-10"
                     v-if="scope.row.status === 'FAIL' && scope.row.prize_type === 'ENVELOPE'"
                     @click="againReceive(scope.row)">补发</el-button>
          <!-- <el-button type="text"
                     class="mr-10"
                     v-if="scope.row.status === 'FAIL' && scope.row.prize_type === 'EXPENSES'"
                     @click="batchOneRecharge(scope.row.id)">话费补发</el-button> -->
        </template>
      </el-table-column>
    </table-page>
    <el-dialog title="提示"
               :visible.sync="dialogVisible"
               width="40%">
      <div v-if="statistics"
           class="el-dialog-container">
        <div class="el-dialog-h1">红包</div>
        <div class="el-dialog-h2">已领取红包统计</div>
        <div>已领取金额：{{statistics.receiveTotalMoney}}元； 已领取数：{{statistics.receiveTotal}}个；</div>
        <div class="el-dialog-h1">明细</div>
        <div v-if="statistics.prizes.length>0">
          <div v-for="(item,index) in statistics.prizes"
               :key="index">
            <div>{{item}}</div>
          </div>
        </div>
        <div v-else>
          本次活动暂无现金红包奖品
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import TablePage from '@/components/TablePage'
import FileExport from '@/components/FileExport'
import { questionLuckyLogs, luckyReceiveEnvelope, questionLuckyStatisticals, batchRecharge, batchOneRecharge } from '@/api/market'
export default {
  name: 'LuckyRecords',
  data () {
    return {
      questionLuckyLogs,
      dialogVisible: false,
      luckyQrcodesExport_url: '',
      luckyLogExport_url: '',
      search: [
        {
          type: 'input',
          name: '手机号码',
          placeholder: '请输入手机号码',
          key: 'tel',
          value: ''
        }
      ],
      exportParams: null,
      statistics: null
    }
  },
  components: {
    TablePage,
    FileExport
  },
  methods: {
    async batch () {
      const loading = this.$loading({
        lock: true,
        text: '补发中，请勿操作',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const res = await batchRecharge({
        activity_id: this.$route.query.id
      })
      loading.close()
      if (res.meta.code === 0) {
        this.$message.success('补发成功')
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async batchOneRecharge (logId) {
      const res = await batchOneRecharge({
        log_id: logId
      })
      if (res.meta.code === 0) {
        this.$message.success('补发成功')
        this.$refs.table.fetch()
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async fetchStatisticals () {
      const res = await questionLuckyStatisticals({
        activity_id: this.$route.query.id
      })
      if (res.meta.code === 0) {
        this.statistics = res.data
        this.dialogVisible = true
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    fetchSuccess (e) {
      this.exportParams = e.export
    },
    async againReceive (item) {
      this.$confirm('确认补发？', {
        type: 'warning'
      }).then(async () => {
        const res = await luckyReceiveEnvelope({
          log_id: item.id
        })
        if (res.meta.code === 0) {
          this.$message.success('补发成功')
          this.$refs.table.fetch()
        } else {
          this.$message.error(res.meta.msg)
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.el-dialog-container {
  div {
    margin-top: 10px;
  }
  .el-dialog-h1 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
    color: #333;
  }
  .el-dialog-h2 {
    font-size: 16px;
    font-weight: bolder;
    color: #494949;
  }
}
</style>
